import React, { useState } from 'react';
import Card from './card'
import Switch from "react-switch";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";

import { ReactComponent as PhoneIcon } from '../../assets/icons/phoneIcon.svg'

import { PriceCardsCont } from '../../styles/components/home.styled';


const PriceCards = ({ cards, title, text }) => {
  const [price, setPrice] = useState(true)
  return(
    <PriceCardsCont>
      <h2> {title} </h2>
      <p> {text} </p>
      <div className='priceses' >
        <div className='switch'>
          <span className={price ? '' : 'bold'}>Mensual</span>
          <Switch
            onChange={() => setPrice(!price)}
            checked={price}
            offColor="#6873E5"
            onColor="#1EA1F1"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            width={48}
            height={24}
          />
          <span className={price ? 'bold' : ''}>Anual</span>
        </div>
        <div className='cards'>
          {cards.map((data,i ) => (
            data.popular && 
            <Card 
              id={i}
              nombre={data.nombre}
              descripcion={data.descripcion}
              precioAnual={data.precioAnual}
              precioMensual={data.precioMensual}
              users={data.users}
              tableros={data.tableros}
              busquedas={data.busquedas}
              popular={data.popular}
              price={price}
              descuento={data.descuento}
              url={data.url}
              device={data.popular && 'mobile'}
            />
          ))}
          {cards.map((data,i ) => (
            <Card 
              id={i}
              nombre={data.nombre}
              descripcion={data.descripcion}
              precioAnual={data.precioAnual}
              precioMensual={data.precioMensual}
              users={data.users}
              tableros={data.tableros}
              busquedas={data.busquedas}
              popular={data.popular}
              price={price}
              descuento={data.descuento}
              url={data.url}
              device={data.popular && 'desktop'}
            />
          ))}
        </div>
      </div>
      <div className='icon-phone'>
        <PhoneIcon />
      </div>
      <p className='help'>¿Necesitas ayuda con los siguientes pasos, o no estás seguro cuál es el plan ideal para ti?</p>
      <Link to="/#contact">¡Contáctanos! Programa una llamada con un asesor. </Link>
          
    </PriceCardsCont>
  )
};

export default PriceCards;