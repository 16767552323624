import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image = (props) => {
  const { src, alt, className } = props;
  return (
    <StaticQuery 
      query={graphql`
        query{
          images: allFile{
            edges{
              node{
                relativePath
                childImageSharp{
                  fluid(maxWidth: 2000){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.images.edges.find(image => {
          return image.node.relativePath.includes(src);
        })
        if (!image) return null;
        return <Img alt={alt} fluid={image.node.childImageSharp.fluid} className={className}/>
      }}
    />
  );
};
export default Image;
