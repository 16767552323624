import React from "react";
import { ContactCont } from "../../styles/components/home.styled";
import Form from "./form";


const Contact = ({ h2, p }) => {
  return (
    <ContactCont>
      <div className="contact-content">
        <h2>{h2}</h2>
        <p>{p}</p>
        <Form />
      </div>
    </ContactCont>
  );
};

export default Contact;
