import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/home/header";
import HowDo from "../components/home/how-do";
import Video from "../components/home/video";
import PriceCards from "../components/home/price-cards";
import Opinions from "../components/home/opinions";
import Demo from "../components/home/demo";
import Contact from "../components/home/contact";
import Slider from "../components/home/Slider";

// import loader from "../assets/loaders/loader-blue300px.gif";

import { HomeCont } from "../styles/components/home.styled";

// markup
const Home = () => {
  // const [loading, setLoading] = useState(true)

  // setTimeout(() => setLoading(false), 4000)

  return (
    <Layout>
      {/* {
        loading &&
        <div style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          zIndex: '2',
          background: 'rgb(0,0,0,0.7)',
        }}
        >
          <div>
            <img src={loader} alt='MOLoader' style={{background: "none"}}/>
          </div>
        </div>
      } */}
      <Seo title="Home" />
      <HomeCont>
        <Header
          h1={(<>La plataforma de monitoreo más completa de <b>México</b></>)}
          button="Empieza gratis ahora"
          url="https://app.myoblek.com/signup"
          urlDemo="/#contact"
          urlLogIn='https://app.myoblek.com/'
          urlSignUp='https://app.myoblek.com/signup'
        />
        <section id="que-hacemos">
          <HowDo
            h2="¿Qué hacemos?"
            p="Recopilamos data de medios tradicionales y digitales, también de redes sociales y los unimos en una"
            a=" potente plataforma."
            article1={{
              h2: "Medios Tradicionales",
              p:
                "Monitoreamos medios tradicionales y recopilamos data para obtener información importante para ti.",
            }}
            article2={{
              h2: "Medios Digitales",
              p:
                "Rastreamos y recopilamos data al momento en medios digitales, te damos la información más relevante para ti. ",
            }}
            article3={{
              h2: "Redes Sociales",
              p:
                "Próximamente haremos seguimiento de redes sociales, para brindarte la información más importante para ti.",
            }}
          />
        </section>
        <section id="nosotros">
          <Video
            title="Conoce nuestra plataforma"
            text="Descubre cómo MyOblek va a simplificar y automatizar la forma en la que realizas tu monitoreo de medios de comunicación."
            url="https://youtu.be/QS2JAwNHiXk"
          />
        </section>
        <Slider />
        <Opinions
          slides={[
            {
              text:'"Esta es una plataforma muy buena, es una versión refinada del resto, me gusta mucho por la cantidad de datos que manejan"',
              name: "Martín Velazquéz",
            },
            {
              text:'"La plataforma es muy intuitiva, a pesar de que tenga muchas funcionalidades MyOblek lo hace ver muy simplificado, lo cual ayuda mucho a nivel operativo y análisis"',
              name: "Julian Andrade",
            },
            {
              text:'"La plataforma tiene muchas cosas positivas, la información que maneja es muy amplia, las operaciones se pueden hacer de una manera muy fácil"',
              name: "Santiago Pérez",
            },
            {
              text:'"MyOblek es impresionante, es una herramienta que te puede dar información más rápida de lo que estás buscando y al momento de utilizarlo me pareció súper fácil"',
              name: "Hugo Martínez",
            },
          ]}
        />
        <section id="precios">
          <PriceCards
            title="Los precios de MyOblek"
            text="Encuentra el plan perfecto para ti, contáctanos si tienes dudas."
            cards={[
              {
                nombre: "individual",
                descripcion:
                  "Ideal para emprendedores y figuras públicas en proceso de construcción y posicionamiento de su marca.",
                precioAnual: "23,400",
                precioMensual: "3,900",
                users: 1,
                tableros: 5,
                busquedas: 5,
                popular: false,
                descuento: false,
                url: "https://app.myoblek.com/signup",
              },
              {
                nombre: "equipo",
                descripcion:
                  "La herramienta perfecta para equipos de trabajo pequeños con un alto nivel de productividad.",
                precioAnual: "53,400",
                precioMensual: "8,900",
                users: 5,
                tableros: 15,
                busquedas: 25,
                popular: true,
                descuento: true,
                url: "https://app.myoblek.com/signup",
              },
              {
                nombre: "corporativo",
                descripcion:
                  "El plan especializado para grandes equipos de trabajo con actividades avanzadas que requieren un mayór control y precisión de la data.",
                precioAnual: "85,440",
                precioMensual: "14,240",
                users: 10,
                tableros: 30,
                busquedas: 50,
                popular: false,
                descuento: false,
                url: "https://app.myoblek.com/signup",
              },
                {
                    nombre: "gratuito",
                    descripcion: "El plan para empezar a conocer MyOblek y realizar tus búsquedas de menciones en los medios de comunicación. Construye tu búsqueda y consulta las novedades cada día.",
                    precioAnual: "0,00",
                    precioMensual: "0,00",
                    users: 1,
                    tableros: 1,
                    busquedas: 3,
                    popular: false,
                    descuento: false,
                    url: "https://app.myoblek.com/signup",
                }
            ]}
          />
        </section>
        <Demo
          button="¡Empieza gratis ahora!"
          bg="#6873E5"
          url="https://app.myoblek.com/signup"
          text="Hemos obtenido una base de datos con más de 160 millones de registros de periódicos, radio, televisión, portales web, redes sociales y bases de datos públicas. Procesamos más de 440,000 notas al mes."
          title="Generamos más de 120 modelos de análisis propios "
        />
        {/* <section id="blog">
          <Blog />
        </section> */}
        <section id="contact">
          <Contact
            h2="¿Quieres más información sobre MyOblek?"
            p="Ponte en contacto con nosotros para agendar una demo"
          />
        </section>
      </HomeCont>
    </Layout>
  );
};

export default Home;
