import React, { useState } from 'react'
import SwiperCore, { Controller, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";

import img1 from '../../assets/images/01 filtros.jpg'
import img2 from '../../assets/images/02 tableros.jpg'
import img3 from '../../assets/images/03 resultados.jpg'
import img4 from '../../assets/images/04 reportes.jpg'

import { SliderCont } from '../../styles/components/home.styled'

SwiperCore.use([Controller, Navigation, Pagination]);

const Slider = () => {
    const [controlledSwiper, setControlledSwiper] = useState(null);
  return (
    <SliderCont> 
      <div className='screen'>
        <Swiper  
          onSwiper={setControlledSwiper} 
          className='screen'
          allowTouchMove={false}
          spaceBetween={10}
        >
          <SwiperSlide><img src={img1} alt='' /></SwiperSlide>
          <SwiperSlide><img src={img2} alt='' /></SwiperSlide>
          <SwiperSlide><img src={img3} alt='' /></SwiperSlide>
          <SwiperSlide><img src={img4} alt='' /></SwiperSlide>
        </Swiper>
      </div>
      <Swiper 
        controller={{ control: controlledSwiper }}
        navigation
        pagination={{ clickable: true, type: 'fraction' }}
        className='content'
        allowTouchMove={false}
        spaceBetween={10}
      >
        <SwiperSlide>
          <h3 className='title'>Detecta menciones en tiempo real.</h3>
          <p className='text'>Personaliza y filtra lo que quieras monitorear con nuestra búsqueda avanzada.</p>
        </SwiperSlide>
        <SwiperSlide>
          <h3 className='title'>Analiza la data mediática en una sola plataforma.</h3>
          <p className='text'>Crea tableros de monitoreo y grafica los datos.</p>
        </SwiperSlide>
        <SwiperSlide>
          <h3 className='title'>Compárate con tu competencia.</h3>
          <p className='text'>Mide tus resultados con indicadores relevantes para ti.</p>
        </SwiperSlide>
        <SwiperSlide>
          <h3 className='title'>Mitiga riesgos con información instantánea.</h3>
          <p className='text'>Automatiza tus reportes de comunicación.</p>
        </SwiperSlide>
      </Swiper>
      <div className='bg-blue'></div>
    </SliderCont>
  )
}

export default Slider