import React from "react";
import { OpinionCont } from "../../styles/components/home.styled";
import { FaStar, FaRegStar } from "react-icons/fa";

const Opinion = ({ text, name }) => {
  return (
    <OpinionCont className='slideOpi'>
      <div className="opinionSlide">
        <div className="textSlide">{text}</div>
        <div className='bottom'>
          <div className='stars'>
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
            <FaRegStar />
          </div>
          <div className="nameSlide">{name}</div>
        </div>
      </div>
    </OpinionCont>
  );
};

export default Opinion;
