import React from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRightWhite.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/userIcon.svg'
import { ReactComponent as UsersIcon } from '../../assets/icons/usersIcon.svg'
import { ReactComponent as TablerosIcon } from '../../assets/icons/browserIcon.svg'
import { ReactComponent as BusquedasIcon } from '../../assets/icons/searchIcon.svg'

import { PriceCardCont } from '../../styles/components/home.styled';


const Card = ({ id, price, nombre, descripcion, precioAnual, precioMensual, users, tableros, busquedas, popular, url, descuento, device }) => {
  return(
    <PriceCardCont id={id} popular={popular} nombre={nombre} className={device}>
      {popular && (<span className='popular'> MÁS POPULAR </span>)}
        <div className='description-card'>
            <h2 className='title'> {nombre === 'gratuito' ? '¡Prueba el plan gratuito!' : nombre}</h2>
            <div className='content-card'>
                {nombre !== 'gratuito' ?
                    <div className='price-card'>
                        <div className='price'>
                            <span className='costo'> {price ? precioAnual : precioMensual } </span> <span className='decimal'>.00</span>
                        </div>
                        <span className='moneda'> $MXN </span>
                        <span className={`descuento ${price && descuento && 'on'}`}> 50% DE DESCUENTO </span>
                    </div>
                    : ''
                }
                {nombre !== 'gratuito' ? <hr /> : '' }
                <span className='description'> {descripcion} </span>
            </div>
        </div>
        <div>
            <div className='servicios'>
                {nombre !== 'gratuito' ? <p className='incluye'> Este plan incluye: </p> : '' }
                <div className='servicios-item'>
                    {users > 1 ? <UsersIcon /> : <UserIcon />}
                    <p> {users} {users > 1 ? 'Usuarios' : 'Usuario'} </p>
                </div>
                {nombre !== 'gratuito' ?
                    <div className='servicios-item'>
                        <TablerosIcon />
                        <p> {tableros} tableros </p>
                    </div>
                    : ''
                }
                <div className='servicios-item'>
                    <BusquedasIcon />
                    <p> {busquedas} búsquedas </p>
                </div>
            </div>
            <a href={url} className='button' > <span> Elegir plan </span> <ArrowIcon /> </a>
        </div>
    </PriceCardCont>
  )
};

export default Card;