import React from 'react';
import { VideoCont } from '../../styles/components/home.styled';
import ReactPlayer from 'react-player';


const video = ({ title, text, url }) => {
  return(
    <VideoCont>
      <div className='content' >
        <h2> {title} </h2>
        <p> {text} </p>
      </div>
      <div className='video'>
        <ReactPlayer url={url} controls={true} width='100%' height='100%' trasnparent={0}/>
      </div>
    </VideoCont>
  )
};

export default video;