import styled from "styled-components";
import {
  colors,
  device,
  flexColumn,
  flexRow,
  flexColumnReverse,
} from "../variables";
import imgBgDesktop from "../../assets/images/bg-phone-desktop.png";
import imgBgTablet from "../../assets/images/bg-phone-tablet.png";
import imgBgMObile from "../../assets/images/bg-phone-mobile.png";
import nextIcon from "../../assets/icons/nextSlider.svg";
import prevIcon from "../../assets/icons/prevSlider.svg";
import bgHeader from "../../assets/images/background.png";

export const HomeCont = styled.div`
  background: url("${bgHeader}") top right no-repeat;
  background-size: 80%;
  @media (max-width: 768px) {
    background: url("${bgHeader}") top right no-repeat;
    background-size: 150%;
  }
  @media (max-width: 480px) {
    background: url("${bgHeader}") top right no-repeat;
    background-size: 250%;
  }
`;

export const HeaderCont = styled.div`
  margin: 0;
  .headerCont {
    ${flexRow}
    width:100%;
    height: calc(100vh - 55px);
    &-screens {
      ${flexRow}
      width:50%;
      height: 100%;
      align-items: center;
      position: relative;
      .bg-blue {
        background-color: ${colors.torquioseBlue};
        opacity: 0.75;
        height: 95%;
        width: 95%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0 0 110px 0;
      }
      .img {
        width: 110%;
      }
    }
    &-content {
      ${flexColumn}
      align-items:flex-start;
      justify-content: center;
      width: 50%;
      height: 100%;
      padding-left: 40px;
      h1 {
        font-family: Montserrat Bold, sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 63px;
        line-height: 79px;
        color: ${colors.richBlack};
        margin-bottom: 25px;
        margin-right: 5vw;
        b {
          font-family: Montserrat Extra Bold, sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 63px;
          line-height: 79px;
          color: ${colors.richBlack};
        }
      }
      .header-button {
        background-color: ${colors.richBlack};
        color: ${colors.white};
        text-decoration: none;
        height: 60px;
        width: 100%;
        max-width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 40px;
        border-radius: 4px;
        cursor: pointer;
        span {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 29px;
        }
      }
      .header-demo {
        height: 60px;
        width: 100%;
        max-width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        span {
          cursor: pointer;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #00aaab;
          border-bottom: solid #00aaab 2px;
        }
      }
      .header-login {
        background-color: ${colors.purple};
        color: ${colors.white};
        text-decoration: none;
        height: 60px;
        width: 100%;
        max-width: 350px;
        justify-content: center;
        align-items: center;
        padding: 15px 40px;
        border-radius: 4px;
        margin-top: 20px;
        display: none;
        span {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 29px;
        }
      }
      .header-SignUp {
        color: ${colors.richBlack};
        text-decoration: none;
        height: 60px;
        width: 100%;
        max-width: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 40px;
        border-radius: 4px;
        margin-top: 20px;
        display: none;
        span {
          font-family: Montserrat Bold;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 29px;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 1440px) {
    .headerCont {
      &-screens {
        width: 55%;
        .bg-blue {
          height: 100%;
        }
      }
      &-content {
        width: 40%;
        h1 {
          font-size: 48px;
          line-height: 59px;
          b {
            font-size: 48px;
            line-height: 59px;
          }
        }
        .header-button {
          padding: 15px 30px;
          span {
            font-size: 24px;
            line-height: 29px;
          }
        }
        .header-demo {
          span {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .headerCont {
      max-height: 524px;
      &-screens {
        width: 40%;
        .img {
          transform: scale(1.2) translate(-17px, 0px);
        }
      }
      &-content {
        width: 60%;
        h1 {
          font-family: Montserrat Extra Bold, sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 48px;
          line-height: 58px;
          margin-bottom: 20px;
          b {
            font-size: 48px;
            line-height: 58px;
          }
        }
        .header-button {
        }
      }
    }
  }
  @media (max-width: 720px) {
    .headerCont {
      &-screens {
        display: none;
      }
      &-content {
        width: 100%;
        justify-content: center;
        padding: 0 5%;
        align-items: center;
        h1 {
          font-family: Montserrat Bold, sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 34px;
          line-height: 46px;
          margin-bottom: 20px;
          text-align: center;
          margin-right: 0vw;
          b{
            font-family: Montserrat Extra Bold, sans-serif;
            font-size: 34px;
            line-height: 46px;
          }
        }
        .header-login {
          display: flex;
        }
        .header-SignUp {
          display: flex;
        }
        .header-demo {
          display: none;
        }
      }
    }
  }
`;

export const AboutCont = styled.div`
  width: 100%;
  background-color: none;
  text-align: center;
  display: block;
  .aboutHeader {
    h2 {
      font-size: 34px;
      font-weight: 700;
      margin-top: 150px;
    }
  }
  .aboutBody {
    ${flexRow}
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 768px) {
    .aboutBody {
      justify-content: space-between;
    }
  }
  @media (max-width: 480px) {
    .aboutHeader {
      h2 {
        font-size: 34px;
        font-weight: 700;
        margin-top: 80px;
      }
    }
    .aboutBody {
      justify-content: center;
    }
  }
`;

export const CardCont = styled.div`
  background-color: #6873e5;
  width: 100%;
  max-width: 20vw;
  text-align: left;
  padding: 32px 28px;
  margin-top: 5vw;
  min-height: 250px;
  border-radius: 4px;
  align-items: center;
  img {
    height: 65px;
    width: 65px;
  }
  p {
    color: white;
    margin-top: 32px;
    font-weight: 700;
    font-size: 22px;
  }
  @media (max-width: 1300px) {
    width: 100%;
    max-width: 393px;
    text-align: left;
    margin-top: 8vw;
    padding: 32px 28px;
    min-height: 230px;
    border-radius: 4px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    max-width: 40vw;
    text-align: left;
    padding: 32px 28px;
    min-height: 230px;
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-width: 40vw;
    text-align: left;
    padding: 32px 28px;
    min-height: 250px;
    border-radius: 4px;
  }
  @media (max-width: 480px) {
    width: 100%;
    max-width: none;
    text-align: left;
    padding: 28px 20px;
    min-height: auto;
    display: flex;
    img {
      height: 65px;
      width: 65px;
    }
    p {
      margin-top: 0px;
      margin-left: 16px;
      font-weight: 700;
      font-size: 18px;
    }
  }
`;

export const DemoCont = styled.div`
  ${flexRow}
  background-color:${(props) => props.bg};
  min-height: 400px;
  width: auto;
  border-radius: 4px;
  margin: 40px 10% 0 10%;
  .button {
    width: 50%;
    ${flexRow}
    align-items:center;
    justify-content: center;
    padding: 20px;
    a {
      ${flexRow}
      width:100%;
      height: 60px;
      max-width: 492px;
      background-color: black;
      color: white;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 13px;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border-radius: 4px;
    }
  }
  .content-text {
    width: 50%;
    ${flexColumn}
    justify-content:center;
    padding: 50px 50px 50px 0;
    h2 {
      font-family: Montserrat Bold, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 34px;
      line-height: 44px;
      color: ${colors.white};
      margin-bottom: 30px;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 37px;
      color: ${colors.white};
    }
  }
  @media (max-width: 1440px) {
    margin-bottom: 60px;
    .button {
      a {
        font-size: 24px;
        line-height: 13px;
      }
    }
    .content-text {
      h2 {
        font-size: 27px;
        line-height: 33px;
      }
      p {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
  @media (max-width: 1024px) {
    ${flexColumnReverse}
    margin: 40px 0;
    border-radius: 0;
    .button {
      width: 100%;
      padding: 40px 20px;
    }
    .content-text {
      width: 100%;
      padding: 20px;
      h2 {
        font-family: Montserrat Bold, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 33px;
        line-height: 44px;
        text-align: center;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 34px;
        text-align: center;
      }
    }
  }
  @media (max-width: 768px) {
    .button {
      padding: 40px 5vw;
    }
    .content-text {
      padding: 20px 5vw;
      h2 {
        font-family: Montserrat Bold, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 29px;
      }
    }
  }
`;

export const HowDoCont = styled.div`
  .howDoHeader {
    color: ${colors.richBlack};
    text-align: center;
    padding-top: 140px;
    padding-bottom: 0px;
    h2 {
      font-family: Montserrat Extra Bold, sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 42px;
      line-height: 46px;
      margin-bottom: 21px;
    }
    p {
      font-size: 22px;
      font-weight: 400;
      margin: 0 23%;
      a {
        color: ${colors.richBlack};
        font-size: 22px;
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
  .howDoBody {
    min-height: 300px;
    .container {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 0;
      .articleHow {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 400px;
        min-height: 328px;
        margin-top: 40px;
        background-color: none;
        &-title {
          color: ${colors.richBlack};
          text-align: center;
          background-color: none;
          h2 {
            font-size: 27px;
            font-family: Montserrat Bold, sans-serif;
            font-style: normal;
            font-weight: 800;
          }
        }
        .custom {
          .articleHow-services-element {
            margin-right: 16px;
          }
        }
        &-services {
          display: flex;
          justify-content: space-around;
          &-element {
            display: block;
            width: 90px;
            margin-top: 30px;
            .purple {
              background-color: #6873e5;
            }
            .cian {
              background-color: #1ea1f1;
            }
            .white {
              background-color: white;
            }
            .purpleT {
              color: #6873e5;
            }
            .cianT {
              color: #1ea1f1;
            }
            .greyT {
              color: grey;
            }
            &-box {
              width: 90px;
              height: 90px;
              background-color: blue;
              border-radius: 3px;
              box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.24);
              display: flex;
              flex-direction: row;
              svg {
                margin: auto;
              }
              .comingSoon {
                width: 90px;
                height: 16px;
                transform: translate(0, 40px);
                background-color: #66fcf1;
                position: absolute;
                p {
                  font-weight: 700;
                  font-size: 8px;
                  text-align: center;
                  margin-top: 3px;
                }
              }
            }
            &-name {
              text-align: center;
              margin-top: 15px;
              height: 40px;
              font-size: 12px;
              font-weight: 700;
            }
          }
        }
        .purple {
          background-color: #6873e5;
        }
        .cian {
          background-color: #1ea1f1;
        }
        &-description {
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
          border-radius: 3px;
          overflow: hidden;
          min-height: 90px;
          &-line {
            height: 7px;
            border-radius: 3px 3px 0 0;
          }
          &-p {
            padding: 21px 42px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1440px) {
    padding: 0% 10%;
    .howDoHeader {
      h2 {
        font-size: 36px;
        line-height: 46px;
      }
      p {
        font-size: 24px;
        line-height: 29px;
        margin: 0 5%;
        a {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
    .howDoBody {
      .container {
        .articleHow {
          &-tite {
            h2 {
              font-size: 27px;
              line-height: 33px;
            }
          }
          &-description {
            &-p {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0% 5%;
  }
  @media (max-width: 485px) {
    padding: 0% 5%;
    .howDoHeader {
      padding-top: 100px;
      padding-bottom: 76px;
      h2 {
        font-size: 36px;
        line-height: 47px;
      }
      p {
        margin: 0 15%;
        font-size: 28px;
        line-height: 34px;
        a {
          font-size: 28px;
        }
      }
    }
    .howDoBody {
      .container {
        justify-content: space-around;
        .articleHow {
          .custom {
            max-width: 200px;
            .articleHow-services-element {
              margin-right: 0;
              transform: translate(0px, 0);
            }
          }
          &-services {
            flex-wrap: wrap;
            justify-content: space-around;
            width: 80%;
            margin: auto;
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    .howDoHeader {
      padding-bottom: 60px;
      h2 {
        font-size: 30px;
        line-height: 37px;
      }
      p {
        margin: 0 15%;
        font-size: 22px;
        line-height: 27px;
        a {
          font-size: 22px;
        }
      }
    }
    .howDoBody {
      margin-bottom: 30px;
      .container {
        transform: translate(0, 0px);
        justify-content: center;
        display: block;
        .articleHow {
          &-title {
            color: black;
            text-align: center;
            background-color: none;
            margin-top: 38px;
            h2 {
              font-size: 27px;
            }
          }

          &-services {
            width: 100%;
            &-element {
              &-box {
                height: 80px;
                width: 80px;
                .comingSoon {
                  width: 80px;
                  height: 16px;
                  p {
                    font-weight: 700;
                    font-size: 8px;
                    text-align: center;
                    margin-top: 3px;
                  }
                }
              }
            }
          }
          &-description {
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.16);
            border-radius: 3px;
            overflow: hidden;
            min-height: 90px;
            &-line {
              height: 6px;
              border-radius: 3px 3px 0 0;
            }
            &-p {
              padding: 21px 16px;
              font-weight: 400;
              text-align: center;
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`;

export const VideoCont = styled.div`
  ${flexRow}
  width:100%;
  padding: 0 10vw;
  margin-top: 80px;
  .content {
    width: 35vw;
    ${flexColumn}
    justify-content:center;
    padding-right: 70px;
    h2 {
      font-family: Montserrat Bold, sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 3vw;
      line-height: 3vw;
      text-align: right;
      margin-bottom: 20px;
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 1.7vw;
      line-height: 2.2vw;
      text-align: right;
    }
  }
  .video {
    width: 45vw;
    height: 25.1vw;
  }
  @media (max-width: 768px) {
    ${flexColumn};
    padding: 0 5vw;
    .content {
      width: 100%;
      padding: 0;
      h2 {
        font-family: Montserrat Bold, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 47px;
        text-align: center;
        margin-bottom: 40px;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 151.4%;
        text-align: center;
        margin-bottom: 40px;
      }
    }
    .video {
      width: 100%;
      height: 49.5vw;
    }
  }
  @media (max-width: 480px) {
    .content {
      h2 {
        font-family: Montserrat Bold, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 37px;
        text-align: center;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 151.4%;
        text-align: center;
        margin-bottom: 40px;
      }
    }
  }
`;

export const PriceCardsCont = styled.div`
  ${flexColumn}
  margin-top: 60px;
  h2 {
    font-family: Montserrat Bold;
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 46px;
    width: 100%;
    text-align: center;
    margin: 25px 0;
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 34px;
    width: 100%;
    text-align: center;
    margin: 15px 0;
  }
  padding: 0 10%;
  .priceses {
    ${flexColumn}
    .switch {
      ${flexRow}
      width:100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 27px;
        margin: 0 20px;
        width: 100px;
      }
      .bold {
        font-family: Montserrat Bold, sans-serif;
        font-weight: 800;
      }
    }
    .cards {
      ${flexRow}
      width:100%;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-end;
      .mobile {
        display: none;
      }
    }
  }
  .icon-phone {
    width: 100%;
    ${flexRow}
    justify-content:center;
    margin-top: 40px;
  }
  .help {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.lettersGrey};
  }
  a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-decoration: line;
    color: ${colors.purple};
    text-align: center;
  }
  @media (max-width: 1440px) {
    h2 {
      font-size: 36px;
      line-height: 46px;
    }
    p {
      font-size: 24px;
      line-height: 29px;
    }
    .help {
      font-size: 18px;
      line-height: 22px;
    }
    a {
      font-size: 18px;
      line-height: 22px;
    }
  }
  @media (max-width: 1024px) {
    h2 {
      font-size: 36px;
      line-height: 46px;
    }
    p {
      font-size: 28px;
      line-height: 34px;
    }
    .priceses {
      .cards {
        .mobile {
          display: flex;
        }
        .desktop {
          display: none;
        }
      }
    }
  }
  @media (max-width: 720px) {
    padding: 0 5%;
    h2 {
      font-size: 30px;
      line-height: 37px;
      margin: 45px 0 15px 0;
    }
    p {
      font-size: 22px;
      line-height: 27px;
    }
    .priceses{
      .cards{
        ${flexColumn}
      }
    }
  }
`;

export const PriceCardCont = styled.div`
  ${flexColumn}
  border: ${(props) => (props.nombre === 'gratuito' ? "3px solid #6873E5" : "none")};
  padding: 12px;
  border-radius: 12px;
  min-width: ${(props) => (props.nombre === 'gratuito' ? "1000px" : "330px")};
  max-width: 325px;
  min-height: ${(props) => (props.nombre === 'gratuito' ? "286px" : "auto")};
  max-height: ${(props) => (props.popular ? "auto" : "630px")};
  background-color: ${(props) =>
      props.nombre === 'gratuito' ? "white" : "rgba(102, 252, 241, 0.3)"};
  margin-top: 60px;
  position: relative;
  .popular {
    background-color: ${colors.torquioseBlue};
    padding: 0px 40px;
    border-radius: 12px;
    font-family: Montserrat Bold, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 46px;
    transform: translate(-40px, 0px);
    max-width: 245px;
  }
  .title {
    font-family: Montserrat Extra Bold, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-transform:${(props) => props.nombre !== 'gratuito' ? 'capitalize' : ''};
    width: 100%;
    text-align: center;
    margin: 25px 0 45px 0;
  }
  .content-card {
    ${flexRow}
    .price-card {
      ${flexColumn}
      align-items:flex-end;
      height: auto;
      position: relative;
      min-height: 87px;
      .price {
        ${flexRow}
        width:125px;
        justify-content: flex-end;
        .costo {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 44px;
        }
        .decimal {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
        }
      }
      .moneda {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
      }
      .descuento {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 12px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: ${colors.torquioseBlue};
        padding: 3px 10px;
        border-radius: 5px;
        opacity: 0;
        transform: translate(-10px, 0);
        transition: 0.2s;
      }
      .on {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
    hr {
      border: 1px solid ${colors.torquioseBlue};
      margin: 0 10px;
    }
    .description {
      font-family: Montserrat Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      max-height: ${(props) => (props.popular ? "auto" : "90px")};
      overflow: hidden;
    }
  }
  .servicios {
    .incluye {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      width: 100%;
      margin: 10px 0;
    }
    ${flexColumn}
    &-item {
      ${flexRow}
      width: 280px;
      margin: auto;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      svg {
        height: 56px;
        width: 56px;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: ${(props) => (props.popular ? "bold" : "normal")};
        font-size: 20px;
        line-height: 29px;
        width: 185px;
        text-align: left;
      }
    }
  }
  .button {
    max-width: 220px;
    height: 50px;
    width: 100%;
    background-color: black;
    border-radius: 4px;
    margin: auto;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 35px;
    span {
      font-family: Montserrat Bold;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 13px;
    }
  }
  @media (max-width: 2560px) {
    justify-content: ${(props) => props.nombre === 'gratuito' ? 'space-between' : ''};
    flex-direction: ${(props) => props.nombre === 'gratuito' ? 'row' : 'column'};
    .description-card{
      max-width: ${(props) => props.nombre === 'gratuito' ? '580px' : ''};
      padding-left: ${(props) => props.nombre === 'gratuito' ? '40px' : ''};
    }
    .title{
      text-align: ${(props) => props.nombre === 'gratuito' ? 'inherit' : ''};
    }
    .content-card{
      .description{
        font-size: ${(props) => props.nombre === 'gratuito' ? '20px' : ''};
        line-height: ${(props) => props.nombre === 'gratuito' ? '22px' : ''};
      }
    }
    .servicios{
      padding-top: ${(props) => props.nombre === 'gratuito' ? '20px' : ''};
    }
    
  }
  @media (max-width: 768px) {
    padding-left: ${(props) => props.nombre === 'gratuito' ? '40px' : ''};
    .title {
      max-width: ${(props) => props.nombre === 'gratuito' ? '210px' : ''};
      line-height: ${(props) => props.nombre === 'gratuito' ? '30px' : ''};
    }
    .description-card{
      padding-left: 0px;
    }
    .content-card{
      .price-card{
        max-width: 80px;
        .price{
          .costo{
            font-size: 25px;
          }
        }
      }
      .description{
        font-size:  ${(props) => props.nombre === 'gratuito' ? '12px' : ''};
        line-height: ${(props) => props.nombre === 'gratuito' ? '15px' : ''};
      }
    }    
  }
  @media (max-width: 1024px) {
    padding-left: 40px;
    min-width: 420px;
    max-width: 100%;
    align-items: center;
    ${flexRow}
    .popular {
      position: absolute;
      transform: translate(0px, 0px);
      top: -25px;
      left: 40px;
    }
    .title {
      position: absolute;
      top: 0;
      font-size: 26px;
      left:  ${(props) => props.nombre === 'gratuito' ? '' : '-130px'};
      text-align: ${(props) => props.nombre === 'gratuito' ? 'inherit' : ''};
    }
    .description-card{
      padding-left: 0px;
    }
    .content-card{
      .description{
        font-size: ${(props) => props.nombre === 'gratuito' ? '15px' : ''};
      }
    }
    .servicios {
      margin-left: 20px;
    }
    .button {
      position: absolute;
      bottom: -20px;
      left: 40px;
    }
  }

  @media (max-width: 720px) {
    ${flexColumn}
    width:100%;
    max-height: none;
    min-width: 200px;
    max-width: 330px;
    margin: 40px auto;
    padding-left: 12px;
    .popular {
      position: relative;
      transform: translate(0px, -35px);
      top: 0px;
      left: 0vw;
    }
    .content-card {
      ${flexColumn}
      align-items:center;
      max-width: 250px;
      hr {
        margin: 20px 0;
        width: 100%;
      }
      .description {
        line-height: 15px;
        font-size: 12px;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .title {
      position: relative;
      top: 0;
      left: 0;
      margin: 15px 0 25px 0;
      text-align: center;
      max-width: ${(props) => (props.nombre === 'gratuito' ? "245px" : '')};
    }
    .servicios {
      margin-left: 0px;
      &-item {
        ${flexColumn}
        p {
          text-align: center;
        }
      }
    }
    .button {
      position: relative;
      bottom: 0px;
      left: 0px;
    }
  }
`;

export const ContactCont = styled.div`
  padding-bottom: 50px;
  width: 100%;
  background-size: contain;
  background-position: center;
  background: url("${imgBgDesktop}") bottom center no-repeat;
  background-size: cover;
  .contact-content {
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5%;
    h2 {
      font-size: 34px;
      font-weight: 800;
      color: ${colors.richBlack};
      max-width: 50rem;
      margin-bottom: 28px;
      text-align: center;
      font-family: Montserrat Bold, sans-serif;
    }
    p {
      align-self: flex-start;
      margin-bottom: 4rem;
      color: ${colors.richBlack};
      font-size: 1.2rem;
      font-family: Montserrat Medium, sans-serif;
      font-style: normal;
      font-weight: 500;
      max-width: 50rem;
      margin-top: 0;
      margin-bottom: 28px;
      margin: auto;
      text-align: center;
      margin-bottom: 20px;
    }
    #form-p {
      margin-left: 20px;
    }
  }
  @media (max-width: 1024px) {
    width: auto;
    justify-content: center;
    background: url("${imgBgTablet}");
    .contact-content {
      p {
        margin: auto;
        margin-bottom: 43px;
        font-size: 1.8rem;
      }
      #form-p {
        margin-left: 20px;
        font-size: 1.2rem;
      }
    }
  }
  @media (max-width: 480px) {
    background: url("${imgBgMObile}");
    .contact-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 5%;
      h2 {
        font-size: 30px;
        font-weight: 700;
        color: ${colors.richBlack};
        max-width: 50rem;
        margin-bottom: 28px;
      }
      p {
        align-self: flex-start;
        margin-bottom: 4rem;
        color: ${colors.richBlack};
        font-weight: 300;
        max-width: 50rem;
        margin: auto;
        margin-bottom: 34px;
        font-size: 1.8rem;
      }
      #form-p {
        margin-left: 20px;
        font-size: 1.2rem;
      }
    }
  }
  @media ${device.tablet} {
    .contact-content > p {
      font-size: 1.8rem;
    }
  }
  @media ${device.desktop} {
    display: flex;
    justify-content: space-between;
    .contact-content {
      width: 100%;
      .section-header {
        height: 20rem;
      }
      p {
        font-size: 1.8rem;
        max-width: 50rem;
        align-self: center;
      }
    }
  }
`;

export const ContactFormCont = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50rem;
  input {
    width: 100%;
    max-width: 50rem;
    height: 5.5rem;
    background: white;
    border: 1px solid ${colors.richBlack};
    border-radius: 0.4rem;
    margin-bottom: 2.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: ${colors.richBlack};
    padding-left: 1rem;
  }
  input::placeholder {
    color: ${colors.richBlack};
  }
  .checkbox {
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    display: block;
  }
  .checkbox input[type="checkbox"] {
    display: none;
  }
  .checkbox label {
    position: relative;
  }
  .checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    width: 2.5rem;
    height: 2.5rem;
    transition: transform 0.28s ease;
    border-radius: 0.4rem;
    border: 0.1rem solid ${colors.richBlack};
  }
  .checkbox label:after {
    content: "";
    display: block;
    width: 1rem;
    height: 0.5rem;
    border-bottom: 0.1rem solid ${colors.richBlack};
    border-left: 0.1rem solid ${colors.richBlack};
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 0.9rem;
    left: 0.9rem;
  }
  .checkbox input[type="checkbox"]:checked ~ label::before {
    color: ${colors.richBlack};
  }
  .checkbox input[type="checkbox"]:checked ~ label::after {
    transform: rotate(-45deg) scale(1);
  }
  .checkbox label {
    min-height: 2.5rem;
    display: block;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  .checkbox label span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
  }
  .flex-r {
    display: flex;
    margin-top: 12px;
  }
  .terms-checkbox {
    width: 100%;
    max-width: 50rem;
    display: flex;
    height: 5.5rem;
    margin-top: 20px;
    p {
      font-weight: 500;
      margin-top: 12px;
      margin-left: 1.5rem;
      a {
        color: ${colors.richBlack};
        font-size: 12px;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
  #captcha {
    align-self: flex-start;
  }
  button {
    top: 3rem;
    position: relative;
    width: 100%;
    max-width: 50rem;
    height: 5.5rem;
    border: none;
    border-radius: 0.4rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${colors.white};
    background: ${colors.lightseaGreen};
    &:active {
      cursor: pointer;
    }
    &:disabled {
      background: #979797;
      cursor: not-allowed;
    }
    &:focus {
      outline: none;
    }
  }
  @media ${device.tablet} {
    #form-anchor {
      font-size: 12px;
    }
    #form-p {
      font-size: 12px;
    }
  }
  @media (max-width: 480px) {
    .flex-r {
      display: block;
      margin-top: 12px;
    }
  }
`;

export const BlogCont = styled.div`
  ${flexRow}
  padding:0 10%;
  margin: 40px 0;
  .blog-principal {
    width: 55%;
    .cont-blog {
      ${flexColumn}
      padding: 20px;
      .img {
        width: 100%;
        height: 428px;
        object-fit: cover;
        border-radius: 12px;
      }
      .title {
        font-family: Montserrat Bold, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 46px;
      }
      .date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: ${colors.purple};
      }
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .blogs {
    ${flexColumn}
    width:45%;
    .short-blog {
      background-color: ${colors.lightGrey};
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 25px;
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        margin-bottom: 15px;
      }
      .date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: ${colors.purple};
      }
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .button {
      ${flexRow}
      height:60px;
      background-color: ${colors.richBlack};
      width: 100%;
      align-items: center;
      justify-content: center;
      color: ${colors.white};
      border-radius: 4px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 13px;
      text-decoration: none;
    }
  }
  @media (max-width: 1024px) {
    ${flexColumn}
    .blog-principal {
      width: 100%;
      .cont-blog {
        .img {
          height: 293px;
        }
      }
    }
    .blogs {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: 0 5%;
  }
  @media (max-width: 480px) {
    .blog-principal {
      .cont-blog {
        .img {
          height: 197px;
        }
        .title {
          font-size: 24px;
          line-height: 29px;
        }
        .date {
          font-size: 16px;
          line-height: 20px;
          margin-top: 10px;
        }
        .text {
          display: none;
        }
      }
    }
    .blogs {
      .short-blog {
        .title {
          font-size: 22px;
          line-height: 35px;
        }
        .date {
          font-size: 16px;
          line-height: 20px;
        }
        .text {
          display: none;
        }
      }
    }
  }
`;

export const SliderCont = styled.div`
  ${flexRow}
  margin: 40px 10%;
  position: relative;
  .screen {
    width: 50%;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.2) 32.81%,
        rgba(0, 0, 0, 0.02) 100%
      ),
      linear-gradient(117.1deg, #eeeeee 2.24%, #eaeaea 99.86%);
    background-blend-mode: soft-light, normal;
    box-shadow: inset -2.0348px -2.0348px 2.0348px rgba(0, 0, 0, 0.05),
      inset 2.0348px 2.0348px 2.0348px rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    padding: 17px;
    z-index: 1;
    margin: auto;
    ${flexRow}
    .swiper-container {
      width: 100%;
      padding: 0;
      border-radius: 30px;
      background: transparent;
      box-shadow: none;
      .swiper-wrapper {
        width: 100%;
        .swiper-slide {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .content {
    ${flexRow}
    width:40%;
    height: 40vw;
    max-height: 650px;
    min-height: 580px;
    .swiper-wrapper {
      .swiper-slide {
        ${flexColumn}
        justify-content:space-around;
        .title {
          font-family: Montserrat Extra Bold;
          font-style: normal;
          font-weight: 800;
          font-size: 42px;
          line-height: 58px;
          height: 50%;
          ${flexRow}
          align-items: flex-end;
          padding-bottom: 20px;
        }
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: 37px;
          color: ${colors.richBlack};
          height: 50%;
          padding-top: 30px;
        }
      }
    }
    .swiper-button-next {
      background-color: black;
      background-image: url("${nextIcon}");
      background-repeat: no-repeat;
      background-size: 50% auto;
      background-position: center;
      width: 50px;
      height: 50px;
      border-radius: 3px;
      transition: 0.4s;
      left: 62px;
      bottom: 60px;
      top: auto;
      &::after {
        display: none;
      }
    }
    .swiper-button-prev {
      background-color: black;
      background-image: url("${prevIcon}");
      background-repeat: no-repeat;
      background-size: 50% auto;
      background-position: center;
      width: 50px;
      height: 50px;
      border-radius: 3px;
      transition: 0.4s;
      left: 0px;
      bottom: 60px;
      top: auto;
      &::after {
        display: none;
      }
    }
    .swiper-pagination {
      width: 40px;
      left: 37px;
      bottom: 40px;
    }
  }
  .bg-blue {
    width: 100%;
    height: 50%;
    background: ${colors.torquioseBlue};
    position: absolute;
    z-index: 0;
    bottom: 0;
    border-radius: 24px;
  }
  @media (max-width: 1440px) {
    .content {
      .swiper-wrapper {
        .swiper-slide {
          .title {
            font-size: 36px;
            line-height: 46px;
            padding-bottom: 60px;
          }
          .text {
            font-size: 24px;
            line-height: 29px;
          }
        }
      }
    }
  }
  @media (max-width: 1024px) {
    ${flexColumn}
    .screen {
      width: 90%;
    }
    .content {
      width: 100%;
      height: auto;
      min-height: 380px;
      .swiper-wrapper {
        .swiper-slide {
          ${flexColumn}
          justify-content:center;
          .title {
            height: auto;
            padding: 30px 20px;
            text-align: center;
            font-size: 38px;
            line-height: 58px;
            width: 100%;
            justify-content: center;
            font-family: Montserrat Extra Bold;
          }
          .text {
            height: auto;
            padding: 0 20px 35px 20px;
            text-align: center;
            font-size: 28px;
            line-height: 34px;
          }
        }
      }
      .swiper-button-next {
        left: 50%;
        transform: translate(5px, 0);
        bottom: 18px;
        top: auto;
      }
      .swiper-button-prev {
        left: 50%;
        transform: translate(-55px, 0);
        bottom: 18px;
        top: auto;
      }
      .swiper-pagination {
        width: 40px;
        left: 50%;
        bottom: 0px;
        transform: translate(-20px, 0);
      }
    }

    .bg-blue {
      bottom: 80px;
      height: 40%;
    }
  }
  @media (max-width: 720px) {
    margin: 40px 5%;
    .content {
      min-height: 300px;
      .swiper-wrapper {
        .swiper-slide {
          ${flexColumn}
          justify-content:center;
          .title {
            height: auto;
            padding: 50px 20px 20px 20px;
            text-align: center;
            border-radius: 12px 12px 0 0;
            background: ${colors.torquioseBlue};
            font-size: 24px;
            line-height: 29px;
            align-items: center;
            justify-content: center;
          }
          .text {
            height: auto;
            padding: 20px 20px 50px 20px;
            text-align: center;
            background: ${colors.torquioseBlue};
            border-radius: 0 0 12px 12px;
            margin-bottom: 80px;
            font-size: 22px;
            line-height: 27px;
          }
        }
      }
    }
    .bg-blue {
      display: none;
    }
  }
  @media (max-width: 480px) {
    .screen {
      width: 100%;
    }
  }
`;

export const OpinionsCont = styled.div`
  padding: 0 10%;
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
  .title {
    font-family: Montserrat Bold;
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    margin: 60px 0;
  }
  .swiper-button-next {
    background-color: black;
    background-image: url("${nextIcon}");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 3px;
    transition: 0.4s;
    left: 50%;
    bottom: 30px;
    transform: translate(5px, 0);
    top: auto;
    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    background-color: black;
    background-image: url("${prevIcon}");
    background-repeat: no-repeat;
    background-size: 50% auto;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 3px;
    transition: 0.4s;
    left: 50%;
    bottom: 30px;
    top: auto;
    transform: translate(-55px, 0);
    &::after {
      display: none;
    }
  }
  .swiper-slide {
    height: auto;
    .slideOpi {
      transition: 0.3s;
      background: rgba(${colors.RGBTorquioseBlue}, 0.3);
      border-radius: 12px;
      margin: auto;
    }
  }
  .swiper-slide-active {
    .slideOpi {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }
  }
  .swiper-container {
    padding: 0 0% 100px 0%;
  }
  @media (max-width: 1440px) {
    .title {
      font-size: 36px;
      line-height: 46px;
    }
  }
  @media (max-width: 950px) {
    padding: 0 4%;
    .swiper-container {
      padding: 0 1% 100px 1%;
    }
  }
`;

export const OpinionCont = styled.div`
  height: 100%;
  min-height: 220px;
  display: flex;
  padding: 45px;
  border-radius: 12px;
  max-width: 500px;
  .opinionSlide {
    display: flex;
    flex-direction: column;
    max-width: 566px;
    height: 100%;
    justify-content: space-between;
    .textSlide {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
    .bottom {
      ${flexRow}
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      .stars {
        display: flex;
        align-items: center;
        svg {
          fill: #00aaab;
          height: 18px;
          width: 18px;
        }
      }
      .nameSlide {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: italic;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
      }
    }
  }
  @media (max-width: 1440px) {
    padding: 40px 20px;
    .opinionSlide {
      .textSlide {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;
