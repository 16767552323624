import React from "react";
import { DemoCont } from "../../styles/components/home.styled";

const Demo = ({ button, title, text, bg, url }) => {
  return (
    <DemoCont bg={bg}>
      <div className="button">
        <a href={url}>{button}</a>
      </div>
      <div className="content-text">
        <h2> {title} </h2>
        <p> {text} </p>
      </div>
    </DemoCont>
  );
};

export default Demo;
