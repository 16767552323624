import React from "react";
import { HowDoCont } from "../../styles/components/home.styled";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import {ReactComponent as TelevisionSVG} from "../../assets/icons/tv.svg";
import {ReactComponent as MediosImSVG} from "../../assets/icons/news.svg";
import {ReactComponent as RadioSVG} from "../../assets/icons/radio.svg";
import {ReactComponent as PodcastSVG} from "../../assets/icons/podcast.svg";
import {ReactComponent as PrensaDigSVG} from "../../assets/icons/blog.svg";
import {ReactComponent as BlogSVG} from "../../assets/icons/information.svg";
import {ReactComponent as VideosSVG} from "../../assets/icons/camera.svg";
import {ReactComponent as TwitterSVG} from "../../assets/icons/twitter_copy.svg";
import {ReactComponent as FacebookSVG} from "../../assets/icons/fb.svg";
import {ReactComponent as InstagramSVG} from "../../assets/icons/insta.svg";

const HowDo = ({ h2, p, a, article1, article2, article3 }) => {
  return (
    <HowDoCont>
      <div className="howDoHeader">
        <h2> {h2} </h2>
        <p>
          {p} <Link to="/"> {a} </Link>
        </p>
      </div>
      <div className="howDoBody">
        <div className="container">
          <div className="articleHow">
            <div className="articleHow-title">
              <h2>{article1.h2}</h2>
            </div>
            <div className="articleHow-services">
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box purple">
                  <MediosImSVG />
                </div>
                <div className="articleHow-services-element-name purpleT">
                  <h3>MEDIOS IMPRESOS</h3>
                </div>
              </div>
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box purple">
                  <TelevisionSVG />
                </div>
                <div className="articleHow-services-element-name purpleT">
                  <h3>TELEVISIÓN</h3>
                </div>
              </div>
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box purple">
                  <RadioSVG />
                </div>
                <div className="articleHow-services-element-name purpleT">
                  <h3>RADIO</h3>
                </div>
              </div>
            </div>
            <div className="articleHow-description">
              <div className="articleHow-description-line purple"></div>
              <div className="articleHow-description-p">
                <p> {article1.p} </p>
              </div>
            </div>
          </div>
          <div className="articleHow">
            <div className="articleHow-title">
              <h2>{article2.h2}</h2>
            </div>
            <div className="articleHow-services custom">
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box purple">
                  <PodcastSVG />
                </div>
                <div className="articleHow-services-element-name purpleT">
                  <h3>PODCAST</h3>
                </div>
              </div>
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box purple">
                  <PrensaDigSVG />
                </div>
                <div className="articleHow-services-element-name purpleT">
                  <h3>PRENSA DIGITAL</h3>
                </div>
              </div>
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box purple">
                  <BlogSVG />
                </div>
                <div className="articleHow-services-element-name purpleT">
                  <h3>BLOG</h3>
                </div>
              </div>
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box purple">
                  <VideosSVG />
                </div>
                <div className="articleHow-services-element-name purpleT">
                  <h3>VIDEOS</h3>
                </div>
              </div>
            </div>
            <div className="articleHow-description">
              <div className="articleHow-description-line purple"></div>
              <div className="articleHow-description-p">
                <p> {article2.p} </p>
              </div>
            </div>
          </div>
          <div className="articleHow">
            <div className="articleHow-title">
              <h2>{article3.h2}</h2>
            </div>
            <div className="articleHow-services">
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box white">
                  <TwitterSVG />
                  <div className="comingSoon">
                    <p>PRÓXIMAMENTE</p>
                  </div>
                </div>
                <div className="articleHow-services-element-name greyT">
                  <h3>TWITTER</h3>
                </div>
              </div>
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box white">
                  <FacebookSVG />
                  <div className="comingSoon">
                    <p>PRÓXIMAMENTE</p>
                  </div>
                </div>
                <div className="articleHow-services-element-name greyT">
                  <h3>FACEBOOK</h3>
                </div>
              </div>
              <div className="articleHow-services-element">
                <div className="articleHow-services-element-box white">
                  <InstagramSVG />
                  <div className="comingSoon">
                    <p>PRÓXIMAMENTE</p>
                  </div>
                </div>
                <div className="articleHow-services-element-name greyT">
                  <h3>INSTAGRAM</h3>
                </div>
              </div>
            </div>
            <div className="articleHow-description">
              <div className="articleHow-description-line cian"></div>
              <div className="articleHow-description-p">
                <p> {article3.p} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HowDoCont>
  );
};

export default HowDo;
