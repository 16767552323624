import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import Opinion from "./opinion";
import { OpinionsCont } from "../../styles/components/home.styled";

SwiperCore.use([Navigation, Pagination]);

const Opinions = ({ slides }) => {
  return (
    <OpinionsCont>
      <h3 className='title'>Lo que opinan nuestros clientes </h3>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true, type: "fraction" }}
        loop
        centeredSlides
        centeredSlidesBounds
        breakpoints={{
          951: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <Opinion text={slide.text} name={slide.name} />
          </SwiperSlide>
        ))}
      </Swiper>
    </OpinionsCont>
  );
};
export default Opinions;
