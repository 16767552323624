import React, { useState } from "react";
import { ContactFormCont } from "../../styles/components/home.styled";
import ReCAPTCHA from "react-google-recaptcha";
import MAIL_SERVICE from "../../services";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";

const Form = () => {
  const [status, setStatus] = useState("");
  const [capcha, setCapcha] = useState(false)

  const onChange = (value) => {
    setCapcha(value);
  };
  
  const { register, handleSubmit, errors, formState } = useForm({mode: 'onChange'});

  const submitForm = async (e) => {
    const data = { name: e.name, mail: e.mail, phone: e.phone, company: e.company };
    const { status } = await MAIL_SERVICE.contact(data);
    if (status === 201) {
      setStatus("SUCCESS");
      setTimeout(() => {
        setStatus("");
      }, 3000);
    } else {
      setStatus("ERROR");
    }
  };

  return (
    <ContactFormCont onSubmit={handleSubmit(submitForm)}>
      <input
        name="name"
        type="text"
        placeholder="Nombre"
        {...register("name", { required: true })}
      />
      <input
        name="mail"
        type="email"
        placeholder="Correo Electrónico"
        {...register("mail", {
          required: true,
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i,
        })}
      />
      <input
        name="phone"
        type="text"
        placeholder="Teléfono"
        {...register("phone", { required: true })}
      />
      <input
        name="company"
        type="text"
        placeholder="Nombre de la Empresa"
        {...register("company", { required: true })}
      />
      <div className="flex-r">
        <div className="terms-checkbox">
          <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox"
              {...register("acept", { required: true })}
            />
            <label htmlFor="checkbox" />
          </div>
          <p id="form-p">
            {/* eslint-disable-next-line */}
            Acepto los{" "}
            <Link to="/terminos" id="form-anchor">
              términos y condiciones
            </Link>
            .
          </p>
        </div>
        <ReCAPTCHA
          id="captcha"
          sitekey="6LdCn9IZAAAAADuuvjvct5jbosNC9T8OUUeL2IUg"
          onChange={onChange}
          theme="dark"
        />
      </div>

      {status === "SUCCESS" ? (
        <button disabled={true}>GRACIAS</button>
      ) : (
        <button disabled={formState.isValid && capcha ? false : true }>CONTÁCTANOS</button>
      )}
      {status === "ERROR" && <button disabled={true}>ERROR</button>}
    </ContactFormCont>
  );
};

export default Form;
