import axios from "axios";
let baseURL = "https://server.elmediodemedios.com";

const service = axios.create({ baseURL });

const MAIL_SERVICE = {
  contact: async (data) =>
    await service.post("/contact-form-myoblek", { data }),
};

export default MAIL_SERVICE;
