import React from "react";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";

import { HeaderCont } from '../../styles/components/home.styled'
import Image from '../auth/image';
import { ReactComponent as ArrowRW } from '../../assets/icons/arrowRightWhite.svg'


const Header = ({ h1, button, url , urlLogIn, urlDemo, urlSignUp}) => {
  return (
    <HeaderCont>
      <div className='headerCont'>
        <div className='headerCont-screens'>
          <div className='bg-blue' />
          <Image className='img' src='pantallas.png' alt='pantalla de app my oblek' />
        </div>
        <div className='headerCont-content'>
          <h1>{h1}</h1>
          <a className="header-button" href={url}>
            <span>{button}</span>
            <ArrowRW />
          </a>
          <Link className='header-demo' to={urlDemo}>
            <span> Solicita una demostración ahora </span>
          </Link>
          <a className="header-login" href={urlLogIn}>
            <span>Ingresa a la app</span>
          </a>
          <a className="header-SignUp" href={urlSignUp}>
            <span>Crea una cuenta</span>
          </a>
        </div>
      </div>
    </HeaderCont>
  );
};

export default Header;
